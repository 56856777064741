function getNewRouteLang(pathname) {
  if (!pathname) {
    return null
  }

  let parts = pathname.split("/").filter(i => !!i)
  if (!parts.length) {
    return null
  }

  if (["es", "en"].indexOf(parts[0]) !== -1) {
    return parts[0]
  }

  return "en"
}

export const onRouteUpdate = ({ location }) => {
  const routeLang = getNewRouteLang(location.pathname)
  if (routeLang) {
    // https://docs.netlify.com/routing/redirects/redirect-options/#redirect-by-country-or-language
    window.document.cookie = `nf_lang=${routeLang}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
  }
}
