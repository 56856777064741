// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookies-js": () => import("./../../../src/templates/cookies.js" /* webpackChunkName: "component---src-templates-cookies-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-privacidad-js": () => import("./../../../src/templates/privacidad.js" /* webpackChunkName: "component---src-templates-privacidad-js" */),
  "component---src-templates-properties-by-tag-js": () => import("./../../../src/templates/properties-by-tag.js" /* webpackChunkName: "component---src-templates-properties-by-tag-js" */),
  "component---src-templates-properties-js": () => import("./../../../src/templates/properties.js" /* webpackChunkName: "component---src-templates-properties-js" */),
  "component---src-templates-property-js": () => import("./../../../src/templates/property.js" /* webpackChunkName: "component---src-templates-property-js" */),
  "component---src-templates-subscribe-js": () => import("./../../../src/templates/subscribe.js" /* webpackChunkName: "component---src-templates-subscribe-js" */)
}

